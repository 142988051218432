import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import SwiperSlider from "./components/carousel/SwiperSlider";
import Aboutacademy from "./components/AboutAcademy/Aboutacademy";

function App() {
  return (
    <div className="App">
      <Router>
        <main className="main-slider"> 
          <SwiperSlider />
          <Navbar /> 
        </main>
        <Routes>
          <Route path="/" element={<Aboutacademy/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
