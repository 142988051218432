import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import { EffectCoverflow, Autoplay, Navigation } from "swiper/modules";
import "./carousel.css";
import carousel1 from "../../Assets/realimage1.jpg";
import carousel2 from "../../Assets/realimage2.jpg";
import carousel3 from "../../Assets/image3.jpeg";
import carousel4 from "../../Assets/image4.jpeg";
import carousel5 from "../../Assets/image5.jpeg";

const SwiperSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.realIndex);
  };

  const images = [carousel1, carousel2, carousel3, carousel4, carousel5];
  const slogans = [
    "Transforming athletes into game-changers",
    "From fundamentals to finesse, we shape future stars",
    "Rise above challenges, soar beyond expectations",
    "Teaching fundamentals, nurturing greatness",
    "Building resilience, one rebound at a time"
  ];

  return (
    <div className="container">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 30,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        navigation
        onSlideChange={handleSlideChange}
        modules={[EffectCoverflow, Autoplay, Navigation]}
        className="swiper_container"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className={activeIndex === index ? "fade-in" : "fade-out"}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="main-slogan">
        <h2>{slogans[activeIndex]}</h2>
      </div>
    </div>
  );
};

export default SwiperSlider;


// import React, { useState } from 'react';
// import './carousel.css'; // Ensure this file is in the same directory
// import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
// import carousel1 from "../../Assets/carousel1.jpg";
// import carousel2 from "../../Assets/carousel2.jpg";
// import carousel3 from "../../Assets/carousel3.jpg";
// import carousel4 from "../../Assets/carousel4.jpg";
// import carousel5 from "../../Assets/carousel5.jpg";
// import carousel6 from "../../Assets/carousel6.jpg";

// const SwiperSlider = () => {
//   // Hardcoded items for the carousel
//   const items = [
//     {
//       url: carousel1,
//       name: 'Switzerland',
//       description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, eum!',
//     },
//     {
//       url: carousel2,
//       name: 'Finland',
//       description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, eum!',
//     },
//     {
//       url: carousel3,
//       name: 'Iceland',
//       description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, eum!',
//     },
//     {
//       url: carousel4,
//       name: 'Australia',
//       description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, eum!',
//     },
//     {
//       url: carousel5,
//       name: 'Netherlands',
//       description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, eum!',
//     },
//     {
//       url: carousel6,
//       name: 'Ireland',
//       description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, eum!',
//     },
//   ];

//   const [currentIndex, setCurrentIndex] = useState(1);

//   const nextSlide = () => {
//     setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
//   };

//   const prevSlide = () => {
//     setCurrentIndex((prevIndex) =>
//       (prevIndex - 1 + items.length) % items.length
//     );
//   };

//   return (
//     <div className="container">
//       <div className="slide">
//         {items.map((item, index) => (
//           <div
//             className={`item ${index === currentIndex ? 'active' : ''}`}
//             key={index}
//             style={{ backgroundImage: `url(${item.url})` }}
//           >
//             <div className={`content ${index === currentIndex ? 'visible' : ''}`}>
//               <div className="name">{item.name}</div>
//               <div className="des">{item.description}</div>
//               <button>See More</button>
//             </div>
//           </div>
//         ))}
//       </div>
//       <div className="button">
//         <button className="prev" onClick={prevSlide}>
//           <FaArrowLeft />
//         </button>
//         <button className="next" onClick={nextSlide}>
//           <FaArrowRight />
//         </button>
//       </div>
//     </div>
//   );
// };

// export default SwiperSlider;

// import React, { useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

// const slides = [
//     {
//         id: 1,
//         title: "Switzerland",
//         description: "Experience the beauty of the Swiss Alps.",
//         image: "https://i.ibb.co/qCkd9jS/img1.jpg"
//     },
//     {
//         id: 2,
//         title: "Finland",
//         description: "Explore the serene landscapes of Finland.",
//         image: "https://i.ibb.co/jrRb11q/img2.jpg"
//     },
//     {
//         id: 3,
//         title: "Iceland",
//         description: "Discover the unique natural wonders of Iceland.",
//         image: "https://i.ibb.co/NSwVv8D/img3.jpg"
//     },
//     {
//         id: 4,
//         title: "Australia",
//         description: "Visit the stunning beaches and wildlife of Australia.",
//         image: "https://i.ibb.co/Bq4Q0M8/img4.jpg"
//     },
//     {
//         id: 5,
//         title: "Netherlands",
//         description: "Enjoy the vibrant culture and tulip fields of the Netherlands.",
//         image: "https://i.ibb.co/jTQfmTq/img5.jpg"
//     },
//     {
//         id: 6,
//         title: "Ireland",
//         description: "Experience the lush green landscapes of Ireland.",
//         image: "https://i.ibb.co/RNkk6L0/img6.jpg"
//     },
// ];

// const SwiperSlider = () => {
//     const [currentIndex, setCurrentIndex] = useState(0);

//     const nextSlide = () => {
//         setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
//     };

//     const prevSlide = () => {
//         setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
//     };

//     return (
//         <div className="slider-container" style={sliderContainerStyle}>
//             <div className="slides" style={slidesStyle(currentIndex)}>
//                 {slides.map((slide) => (
//                     <div className="slide" key={slide.id} style={slideStyle(slide)}>
//                         <div className="content" style={contentStyle}>
//                             <h2 style={titleStyle}>{slide.title}</h2>
//                             <p style={descriptionStyle}>{slide.description}</p>
//                             <button style={buttonStyle}>See More</button>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//             <button style={buttonNavStyle} onClick={prevSlide}>
//                 <FontAwesomeIcon icon={faArrowLeft} />
//             </button>
//             <button style={buttonNavStyle} onClick={nextSlide}>
//                 <FontAwesomeIcon icon={faArrowRight} />
//             </button>
//         </div>
//     );
// };

// const sliderContainerStyle = {
//     position: 'relative',
//     width: '1000px',
//     height: '600px',
//     overflow: 'hidden',
//     margin: '0 auto',
//     borderRadius: '20px',
//     boxShadow: '0 30px 50px #dbdbdb',
// };

// const slidesStyle = (currentIndex) => ({
//     display: 'flex',
//     transition: 'transform 0.5s ease',
//     transform: `translateX(-${currentIndex * 100}%)`,
// });

// const slideStyle = (slide) => ({
//     backgroundImage: `url(${slide.image})`,
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//     width: '100%',
//     height: '100%',
//     position: 'relative',
// });

// const contentStyle = {
//     position: 'absolute',
//     bottom: '30px',
//     left: '30px',
//     color: '#fff',
// };

// const titleStyle = {
//     fontSize: '40px',
//     fontWeight: 'bold',
// };

// const descriptionStyle = {
//     margin: '10px 0',
// };

// const buttonStyle = {
//     padding: '10px 20px',
//     background: 'transparent',
//     color: '#fff',
//     border: '1px solid #fff',
//     cursor: 'pointer',
// };

// const buttonNavStyle = {
//     position: 'absolute',
//     top: '50%',
//     transform: 'translateY(-50%)',
//     background: 'rgba(0, 0, 0, 0.5)',
//     color: '#fff',
//     border: 'none',
//     padding: '10px',
//     cursor: 'pointer',
//     borderRadius: '50%',
//     zIndex: 1,
// };

// export default SwiperSlider;
